// src/composables/useSession.ts

import { ref, computed } from 'vue'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { v4 as uuidv4 } from 'uuid'
import client from '@/api/client'
import { useGlobalStore } from "../stores/global";

export function useSession() {
  const store = useGlobalStore()
  const fingerprint = ref<string | null>(null)
  const userData = ref<any>(null)

  const currentGameId = computed(() => {
    return process.env.VUE_APP_GAME_ID
  })

  async function getFingerprintAndInitializeSession(campaignId: string) {
    const fp = await FingerprintJS.load()
    const result = await fp.get()

    if (result) {
      userData.value = {
        screen_resolution: result.components.screenResolution,
        platform: result.components.platform,
        browser_languages: result.components.languages,
        timezone: result.components.timezone,
        browser_vendor: result.components.vendor,
        times_played: + store.timesPlayed,
        selected_person: + store.selectedPerson,
      }

      findSession(campaignId)
    }
  }

  function findSession(campaignId: string) {
    if (!currentGameId.value) {
      console.log("No game id retrieved. Cannot track.")
      return false
    }

    const uuid = uuidv4()
    const sessionId = localStorage.getItem(`SHOT_MACHINE_SESSION_${currentGameId.value}`)

    if (!sessionId) {
      localStorage.setItem(`SHOT_MACHINE_SESSION_${currentGameId.value}`, uuid)
      fingerprint.value = uuid
      console.log("creating session")
    } else {
      fingerprint.value = sessionId
    }

    store.setSession(fingerprint.value)

    if (fingerprint.value !== null) {
      createUpdateSession(fingerprint.value, campaignId)
    }
  }

  function resetSessionId() {
    localStorage.removeItem(`SHOT_MACHINE_SESSION_${currentGameId.value}`)
    fingerprint.value = null
  }

  async function createUpdateSession(identifier: string, campaignId: string) {
    try {
      const res = await client.post(`public/games/${currentGameId.value}/sessions`, {
        identifier: identifier,
        campaign_id: campaignId,
        user_data: userData.value
      })

      console.log(res)
      if (res) {
        console.log(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    fingerprint,
    userData,
    getFingerprintAndInitializeSession,
    findSession,
    createUpdateSession,
    resetSessionId
  }
}