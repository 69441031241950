<template>
  <div class="container py-4 font-bold flex-col align-center uppercase">
    <div class="flex justify-center">
      <div class="vertical-datepicker bg-jm_green rounded-[10px] bg-jm_green rounded-[10px]">
        <div class="datepicker-field">
          <div class="dropdown-trigger" @click="toggleDropdown('day')">
            <span v-if="selectedDay">{{ selectedDay }}</span>
            <span v-else>{{ $t('age_check.day_placeholder') }}</span>
          </div>
          <div v-if="dropdownVisible === 'day'" class="dropdown">
            <div class="dropdown-content">
              <button @click="scrollUp('day')" class="scroll-button up">
                <img :src="arrowUp" />
              </button>
              <div class="dropdown-items" ref="dayContainer">
                <div v-for="day in days" :key="day" @click="selectDay(day)"
                  :class="{ 'dropdown-item': true, selected: day === selectedDay }">
                  {{ day }}
                </div>
              </div>
              <button @click="scrollDown('day')" class="scroll-button down">
                <img :src="arrowDown" />
              </button>
            </div>
          </div>
        </div>
        <div class="datepicker-field">
          <div class="dropdown-trigger" @click="toggleDropdown('month')">
            <span>{{ selectedMonth ? months[selectedMonth - 1].slice(0, 3) : $t('age_check.month_placeholder') }}</span>
          </div>
          <div v-if="dropdownVisible === 'month'" class="dropdown">
            <div class="dropdown-content">
              <button @click="scrollUp('month')" class="scroll-button up">
                <img :src="arrowUp" />
              </button>
              <div class="dropdown-items" ref="monthContainer">
                <div v-for="(month, index) in months" :key="index" @click="selectMonth(index + 1)"
                  :class="{ 'dropdown-item': true, selected: index + 1 === selectedMonth }">
                  {{ month.slice(0, 3) }}
                </div>
              </div>
              <button @click="scrollDown('month')" class="scroll-button down">
                <img :src="arrowDown" />
              </button>
            </div>
          </div>
        </div>
        <div class="datepicker-field">
          <div class="dropdown-trigger" @click="toggleDropdown('year')">
            <span>{{ selectedYear || $t('age_check.year_placeholder') }}</span>
          </div>
          <div v-if="dropdownVisible === 'year'" class="dropdown">
            <div class="dropdown-content">
              <button @click="scrollUp('year')" class="scroll-button up">
                <img :src="arrowUp" />
              </button>
              <div class="dropdown-items" ref="yearContainer">
                <div v-for="year in years" :key="year" @click="selectYear(year)"
                  :class="{ 'dropdown-item': true, selected: year === selectedYear }">
                  {{ year }}
                </div>
              </div>
              <button @click="scrollDown('year')" class="scroll-button down">
                <img :src="arrowDown" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-lg font-regular normal-case leading-tight text-jm_white px-20 text-center mt-4">
      {{ $t("age_check.how_many_people") }}
    </div>
    <div class="flex justify-center w-full">
      <div class="datepicker-field person-picker bg-jm_green rounded-[10px]">
        <div class="dropdown-trigger for-people" @click="toggleDropdown('person')">
          <span>{{ selectedPerson !== null ? selectedPerson : $t('age_check.person') }}
            <span v-if="selectedPerson === 1"> {{ $t('age_check.person') }}</span>
            <span v-else> {{ $t('age_check.people') }}</span>
          </span>
        </div>
        <div v-if="dropdownVisible === 'person'" class="dropdown">
          <div class="dropdown-content">
            <button @click="scrollUp('person')" class="scroll-button up">
              <img :src="arrowUp" />
            </button>
            <div class="dropdown-items" ref="personContainer">
              <div v-for="person in persons" :key="person" @click="selectPerson(person)"
                :class="{ 'dropdown-item': true, selected: person === selectedPerson }">
                {{ person }}
              </div>
            </div>
            <button @click="scrollDown('person')" class="scroll-button down">
              <img :src="arrowDown" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick, computed, watch } from "vue";
import arrowDown from "@/assets/images/misc/arrow_down.png";
import arrowUp from "@/assets/images/misc/arrow_up.png";

import { useGlobalStore } from "../stores/global";

const globalStore = useGlobalStore();

const selectedDay = ref<number | null>(null);
const selectedMonth = ref<number | null>(null);
const selectedYear = ref<number | null>(null);
const selectedPerson = ref<number | null>(1);
const dropdownVisible = ref<string | null>(null);

const days = Array.from({ length: 31 }, (_, i) => i + 1);
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const generateYears = (): number[] => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 101 }, (_, i) => currentYear - i);
};
const years = generateYears();
const persons = [1, 2, 3, 4, 5];

const initializeDebugDate = () => {
  selectedDay.value = 1;
  selectedMonth.value = 2;
  selectedYear.value = 2006;
};

const dayContainer = ref<HTMLElement | null>(null);
const monthContainer = ref<HTMLElement | null>(null);
const yearContainer = ref<HTMLElement | null>(null);
const personContainer = ref<HTMLElement | null>(null);

const toggleDropdown = (type: string) => {
  if (dropdownVisible.value === type) {
    dropdownVisible.value = null;
  } else {
    dropdownVisible.value = type;
    nextTick(() => {
      scrollToCurrent(type);
    });
  }
};

const selectDay = (day: number) => {
  selectedDay.value = day;
  dropdownVisible.value = null;
};

const selectMonth = (month: number) => {
  selectedMonth.value = month;
  dropdownVisible.value = null;
};

const selectYear = (year: number) => {
  selectedYear.value = year;
  dropdownVisible.value = null;
};

const selectPerson = (person: number) => {
  selectedPerson.value = person;
  globalStore.setSelectedPerson(selectedPerson.value);
  dropdownVisible.value = null;
};

const scrollToCurrent = (type: string) => {
  const container =
    type === "day" ? dayContainer.value : type === "month" ? monthContainer.value : type === "year" ? yearContainer.value : personContainer.value;

  if (!container) return;

  const items = container.children as HTMLCollection;
  const selectedItemIndex =
    type === "day"
      ? days.indexOf(selectedDay.value ?? 1)
      : type === "month"
      ? months.indexOf(months[selectedMonth.value ? selectedMonth.value - 1 : 0])
      : type === "year"
      ? years.indexOf(selectedYear.value ?? new Date().getFullYear())
      : persons.indexOf(selectedPerson.value ?? 2);

  const itemHeight = (items[0] as HTMLElement)?.offsetHeight || 0;
  const containerHeight = container.clientHeight;

  const scrollOffset = itemHeight * selectedItemIndex - (containerHeight / 2 - itemHeight / 2);

  container.scrollTop = scrollOffset;
};

const scrollUp = (type: string) => {
  const container =
    type === "day" ? dayContainer.value : type === "month" ? monthContainer.value : type === "year" ? yearContainer.value : personContainer.value;

  if (!container) return;

  smoothScroll(container, -1);
};

const isUser18OrOlder = (day: number | null, month: number | null, year: number | null): boolean => {
  if (day === null || month === null || year === null) {
    return false;
  }

  const today = new Date();
  const birthDate = new Date(year, month - 1, day);

  const age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    return age - 1 >= 18;
  }

  return age >= 18;
};

const is18OrOlder = computed(() => isUser18OrOlder(selectedDay.value, selectedMonth.value, selectedYear.value));


watch([selectedDay, selectedMonth, selectedYear], () => {
  globalStore.confirmAgeCheck(is18OrOlder.value);
}); 

const scrollDown = (type: string) => {
  const container =
    type === "day" ? dayContainer.value : type === "month" ? monthContainer.value : type === "year" ? yearContainer.value : personContainer.value;

  if (!container) return;

  smoothScroll(container, 1);
};

const smoothScroll = (container: HTMLElement, direction: number) => {
  const itemHeight = (container.children[0] as HTMLElement)?.offsetHeight || 0;
  const scrollAmount = itemHeight * direction;

  container.scrollBy({ top: scrollAmount, behavior: "smooth" });
};

onMounted(() => {
  // initializeDebugDate();
});

</script>
<style lang="scss" scoped>
@import "@/assets/sass/main.sass";

.container {
  z-index: 1;
  width: fit-content;
}

.vertical-datepicker {
  display: flex;
  gap: 1rem;
  padding: 0.75rem;
}

.datepicker-field {
  position: relative;
}

.dropdown-trigger {
  padding: 0.5rem;
  border: 1px solid $jm_white;
  border-radius: 10px;
  font-size: 1rem;
  display: flex;
  width: 100px;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  color: $jm_orange;

  &.for-people {
    width: 140px;
  }
}


.dropdown {
  position: absolute;
  bottom: calc(100% + 5px);
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow: hidden;
  z-index: 10;
  animation: slide-up 0.3s ease-out;
  border-radius: 10px;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $jm_green;
  color: rgb(252, 252, 252, 0.3);
  margin-bottom: 20px;
}

.dropdown-items {
  max-height: 120px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
}

.dropdown-item {
  padding: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  background-color: $jm_green;
}

.selected {
  color: $jm_white;
}

.scroll-button {
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  img {
    width: 30px;
  }
}

.person-picker {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  padding: .75rem;
  width: fit-content;
}

@keyframes slide-up {
  from {
    transform: translateY(150%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
