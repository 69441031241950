<template>
  <div>
    <button
      type="button"
      class="rounded-full items-center bg-jm_dark_green py-2 px-2"
      @click="handleButtonClick"
    >
      <img
        :src="close"
        alt="close"
        class="rounded-full"
      />
    </button>
  </div>
</template>
<script lang="ts" setup>
import close from "@/assets/images/misc/close.svg";
import { useGlobalStore } from "../stores/global";

const globalStore = useGlobalStore();


const handleButtonClick = () => {
  globalStore.changeStep("ageCheck");
  globalStore.playTapSound();
  globalStore.resetGame();
};

</script>
<style lang="scss" scoped>
@import "@/assets/sass/main.sass";
</style>
