import axios from 'axios';

// Get credentials from environment variables


const username = process.env.VUE_APP_API_USERNAME;
const password = process.env.VUE_APP_API_PASSWORD;

// Encode credentials to base64
const token = `${username}:${password}`;
const encodedToken = btoa(token);

// Create an Axios instance with base URL and authorization headers
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: `Basic ${encodedToken}`
  }
});

export default axiosInstance;
