<template>
  <DefaultLayout background-image="people">
    <div class="flex flex-col items-center h-full">
      <div class="flex-1 flex flex-col items-center justify-center">
        <img :src="logo" alt="logo" class="w-[150px] mb-6 slide mx-auto" />
        <div class="font-bold text-4xl text-jm_white mb-2 text-center">
          {{ $t("age_check.header") }}
        </div>
        <div
          class="text-lg leading-tight max-w-md text-jm_white px-6 text-center [text-shadow:_0_0px_10px_rgba(0_0_0_/_40%)]">
          {{ $t("age_check.message") }}
        </div>
        <div>
          <AgeAndPeopleSelector class="text-center" />
        </div>
        <PlayButton class="mb-6 slide" />
        <div>
        </div>
      </div>
      <div class="flex-0">
        <img :src="drinkReponsible" alt="drink responsible" class="w-[160px] mb-6" />
        <div class="text-sm uppercase cursor-pointer opacity-50 text-jm_white mb-4 text-center"
          @click="globalStore.changeStep('termsAndConditions')">
          {{ $t("age_check.legal_conditions") }}
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script lang="ts" setup>
import PlayButton from "@/components/PlayButton.vue";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import AgeAndPeopleSelector from "@/components/AgeAndPeopleSelector.vue";
import logo from "@/assets/images/misc/logo.svg";
import drinkReponsible from "@/assets/images/misc/drink-responsible.svg";
import { useGlobalStore } from "../stores/global";

const globalStore = useGlobalStore();
</script>

<style lang="scss" scoped>
@import "@/assets/sass/main.sass";
</style>
