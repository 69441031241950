<template>
  <div>
    <button
      class="text-white py-2 px-6 uppercase text-center w-full text-xl font-bold rounded-lg bg-orange-500"
      @click="handleButtonClick"
    >
      {{ $t("age_check.confirm_button") }}
    </button>
  </div>
</template>
<script lang="ts" setup>
import { useGlobalStore } from "../stores/global";
const globalStore = useGlobalStore();

const handleButtonClick = () => {
  if (globalStore.ageCheckConfirmed) {
    globalStore.changeStep("gameMove");
    globalStore.playBackgroundMusic();
  } else {
    globalStore.changeStep("ageCheckFailed");
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/main.sass";
</style>
