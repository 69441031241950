import { openDB, IDBPDatabase } from 'idb';

let dbPromise: Promise<IDBPDatabase> | null = null;

const isBrowser = typeof window !== 'undefined' && typeof window.indexedDB !== 'undefined';

if (isBrowser) {
  dbPromise = openDB('imagesDb', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('images')) {
        db.createObjectStore('images', { keyPath: 'id' });
      }
    },
  });
}

export const setItem = async (storeName: string, id: string, value: Blob) => {
  if (!isBrowser) {
    console.warn('IndexedDB is not available.');
    return;
  }

  if (!dbPromise) {
    throw new Error('IndexedDB is not initialized.');
  }

  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  
  const data = { id, value };
  
  await store.put(data);
  await tx.done;
};

export const getItem = async (storeName: string, id: string) => {
  if (!isBrowser) {
    console.warn('IndexedDB is not available.');
    return null;
  }

  if (!dbPromise) {
    throw new Error('IndexedDB is not initialized.');
  }

  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  const result = await store.get(id);
  await tx.done;
  return result ? result.value : null;
};

export const clearIndexedDB = async () => {
  if (!isBrowser) {
    console.warn('IndexedDB is not available.');
    return;
  }

  if (!dbPromise) {
    throw new Error('IndexedDB is not initialized.');
  }

  const db = await dbPromise;
  const tx = db.transaction(db.objectStoreNames, 'readwrite');
  
  Array.from(db.objectStoreNames).forEach((storeName: string) => {
    const store = tx.objectStore(storeName);
    store.clear();
  });
  
  await tx.done;
  console.log('IndexedDB cleared');
};
