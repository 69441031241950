import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import i18n from "@/plugins/i18n";
import mitt from 'mitt';
import { createRouter, createWebHistory } from "vue-router";
import '@/assets/sass/main.sass';

const routes = [
  { path: "/:lang(en|de)?", component: App },
  { path: "/:catchAll(.*)", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const eventBus = mitt();

import "./index.css"; // tailwindcss
const pinia = createPinia();

const app = createApp(App);
app.use(router);
app.use(pinia);
app.use(i18n);
app.config.globalProperties.eventBus = eventBus;
app.mount("#app");
