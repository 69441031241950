import { createI18n } from "vue-i18n";

const messages = {
  en: {
    age_check: {
      // Age Check
      header: "SHOT MACHINE",
      message: "To access you must be of legal drinking age in your country of access or older.",
      day: "Day",
      month: "Month",
      year: "Year",
      day_placeholder: "DD",
      month_placeholder: "MM",
      year_placeholder: "YYYY",
      how_many_people: "With how many people will you be playing?",
      person: "person",
      people: "people",
      confirm_button: "Confirm",
      legal_conditions: "Legal conditions",
      // age check erros
      error_day_message: "Please enter a valid day",
      error_month_message: "Please enter a valid month",
      error_year_message: "Please enter a valid year",
    },
    age_check_failed: {
      failed_header: "ARGH",
      failed_subheader: "THAT'S A SHAME",
      failed_content: "We take the youth protection act very seriously. Unfortunately, you cannot take part in the buzzer game.",
      legal_conditions: "Legal conditions",
    },
    image_slider: {
      thats_enough: "That's enough for today.",
      thanks_for_playing: "Thanks for playing!",
      loading: "Loading...",
      please_wait: "Please wait...",
    }
  },
  de: {
    age_check: {
      // Age Check
      header: "SHOT MACHINE",
      message: "Um fortzufahren musst du das gesetzliche Mindestalter für den Konsum von Alkohol in deinem Land erreicht haben.",
      day: "Tag",
      month: "Monat",
      year: "Jahr",
      day_placeholder: "TT",
      month_placeholder: "MM",
      year_placeholder: "JJJJ",
      how_many_people: "Mit wie vielen Personen spielt ihr?",
      person: "Person",
      people: "Personen",
      confirm_button: "Bestätigen",
      legal_conditions: "Rechtliche Hinweise",
      // age check erros
      error_day_message: "Bitte gib einen gültigen Tag ein",
      error_month_message: "Bitte gib einen gültigen Monat ein",
      error_year_message: "Bitte gib ein gültiges Jahr ein",
    },
    age_check_failed: {
      failed_header: "ARGH",
      failed_subheader: "DAS IST SCHADE",
      failed_content: "Wir nehmen das Jugendschutzgesetz sehr ernst. Leider kannst du nicht am Buzzer-Spiel teilnehmen.",
      legal_conditions: "Rechtliche Hinweise",
    },
    image_slider: {
      thats_enough: "Das reicht für heute.",
      thanks_for_playing: "Danke für's Spielen!",
      loading: "Laden...",
      please_wait: "Bitte warten...",
    }
  },
}
const i18n = createI18n({
  locale: 'en',
  legacy: true,
  fallbackLocale: 'en',
  messages
});

export default i18n
